/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../../core/JSONSupport.js";
import { property as e } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { enumeration as t } from "../../../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../../../core/accessorSupport/decorators/subclass.js";
var p;
let a = p = class extends o {
  constructor(r) {
    super(r), this.type = "raster";
  }
  clone() {
    const {
      workspaceId: r,
      dataSourceName: o
    } = this;
    return new p({
      workspaceId: r,
      dataSourceName: o
    });
  }
};
r([t({
  raster: "raster"
})], a.prototype, "type", void 0), r([e({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "dataSourceName", void 0), r([e({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "workspaceId", void 0), a = p = r([s("esri.layers.support.source.RasterDataSource")], a);
export { a as RasterDataSource };